<template>
  <div class="modal-phone-validation flex flex-col items-center py-100 px-64 text-grey-900">
    <img src="/imgs/softoken.svg" alt="token" />

    <h1 class="mt-24 text-32 leading-150 tracking-038">
      {{ $t(translationKeys.title) }}
    </h1>

    <h2 v-if="translationKeys.subtitle" class="mt-17 font-bbva text-18 leading-153 font-medium">
      {{ $t(translationKeys.subtitle) }}
    </h2>

    <p class="mt-16 text-18 leading-156 text-center">
      {{ $t('MODALS.PHONE_VALIDATION.TEXT') }} {{ maskedPhone }}
    </p>

    <app-input
      v-model="code"
      type="text"
      :chars="/[0-9]/"
      :maxlength="4"
      :placeholder="$t('MODALS.PHONE_VALIDATION.PLACEHOLDER')"
      class="input-code mt-31"
      :error="codeError"
    />

    <p v-show="codeError" class="error mt-8 text-12 leading-132 text-grey-900 pl-32">
      {{ $t('MODALS.PHONE_VALIDATION.ERROR') }}
    </p>

    <app-button class="continue mt-56 bg-green-400 font-bbva" @click="sendCode()">{{
      $t('MODALS.PHONE_VALIDATION.SEND_CODE')
    }}</app-button>

    <a
      v-if="allowRequestAgain"
      href="javascript: void(0)"
      class="mt-16 text-blue-400 font-bbva font-medium text-15 leading-160"
      @click="requestAgain()"
      >{{ $t('MODALS.PHONE_VALIDATION.DIDNT_GET') }}</a
    >
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { AppInput } from '@/components/form'
import AppButton from '@/components/AppButton'
import employeeService from '@/shared/services/employeeService'

export default {
  name: 'PhoneValidation',
  components: {
    AppButton,
    AppInput,
  },
  props: {
    maskedPhone: {
      required: true,
      type: String,
    },
    translationKeys: {
      required: true,
      type: Object,
    },
    allowRequestAgain: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    codeError: false,
    code: undefined,
  }),
  methods: {
    ...mapActions('auth', ['setDoubleFactor']),
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    ...mapGetters('auth', ['unmaskedPhone']),
    sendCode() {
      const phone = this.unmaskedPhone()
      this.setRequestInProgressStatus(true)
      employeeService
        .validatePhone(this.code, phone)
        .then((response) => {
          if (response.estado === 'OK') {
            this.setDoubleFactor(true)
            this.$modal.close(true, {
              validated: true,
            })
          } else if (response.estado === 'KO') {
            this.codeError = true
          }
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },
    requestAgain() {
      this.$modal.close(true, {
        requestAgain: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-phone-validation {
  .input-code,
  .error {
    width: 326px;
  }

  .error {
    background-image: url('/imgs/warning.svg');
    background-position: 0 8px;
    background-repeat: no-repeat;
    background-size: 16px;
  }

  .continue {
    width: 205px;
  }
}
</style>

import { maskPhone, maskEmail } from '@/shared/utils/masks'

export default {
  authenticated(state) {
    return process.env.VUE_APP_AUTHENTICATE === 'false' || !!(state.uid && state.token)
  },

  userValidated(state) {
    return !!(state.user && state.user.validationPhone && state.user.validationEmail)
  },

  isSpecialAgreementAllowed(state, getters) {
    return getters.isSenior ? state.isRetired === true : true
  },

  areLoansAllowed(state, getters) {
    return getters.isSenior ? state.isRetired === true : true
  },

  areAutocertificatesAllowed(state, getters) {
    return getters.isSenior ? state.isWidow === false : true
  },

  unmaskedPhone(state) {
    if (!state.user || !state.user.validationPhone) {
      return ''
    }

    return state.user.validationPhone
  },

  maskedPhone(state) {
    if (!state.user || !state.user.validationPhone) {
      return ''
    }

    return maskPhone(state.user.validationPhone)
  },

  maskedEmail(state) {
    if (!state.user || !state.user.validationEmail) {
      return ''
    }

    return maskEmail(state.user.validationEmail)
  },

  isSenior(state) {
    return state.user && state.user.isSenior
  },

  isDc(state) {
    return state.user && state.user.isDc
  },

  isForbidden(state) {
    return state.isForbidden
  },
}
